<template>
  <div>
    <v-progress-linear
      :active="isBusy"
      absolute
      top
      indeterminate
      color="primary"
    />
    <Header
      :title="$t('fileExplorer.fileExplorer').toString()"
      :items="headerItems"
      @createFolder="createFolder"
      @bulkDownload="bulkDownload"
    />
    <div id="integrationsCard" class="card card-custom grid-stack-item-content">
      <div class="card-body column">
        <TableWrapper
          :meta="meta"
          :disable-search="true"
          @reload-data="
            loadFiles(
              $store.getters.currentDirectory === 'files'
                ? ''
                : $store.getters.currentDirectory
            )
          "
        >
          <UploadDropzone></UploadDropzone>
          <table class="table table-head-custom" style="min-width: 100%">
            <thead>
              <tr>
                <th scope="col" class="align-middle">
                  <b-form-group class="pt-1 mb-1">
                    <b-form-checkbox-group v-model="selectAll" size="lg">
                      <b-form-checkbox value="selectAll"></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </th>
                <th scope="col" class="align-middle font-weight-normal">
                  {{ $t("fileExplorer.fileName") }}
                </th>
                <th scope="col" class="align-middle font-weight-normal">
                  {{ $t("fileExplorer.fileSize") }}
                </th>
                <th scope="col" class="align-middle font-weight-normal">
                  {{ $t("fileExplorer.fileType") }}
                </th>
                <th scope="col" class="align-middle font-weight-normal">
                  {{ $t("fileExplorer.lastModified") }}
                </th>
                <th
                  scope="col"
                  class="align-middle text-end font-weight-normal"
                >
                  {{ $t("table.actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(tFile, index) in files"
                :key="index"
                @click.ctrl="selectItem(index)"
                @click.meta="selectItem(index)"
              >
                <!-- select -->
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <b-form-group
                        v-if="tFile.type !== 'back'"
                        class="pt-1 mb-1"
                      >
                        <b-form-checkbox-group v-model="selected" size="lg">
                          <b-form-checkbox :value="index"></b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </div>
                </td>
                <!-- fileName -->
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div v-if="tFile.type === 'directory'">
                        <div
                          class="cursor-pointer"
                          style="user-select: none"
                          @dblclick="changeDirectory(tFile.fileName)"
                        >
                          <i class="fa-light fa-folder pr-3"></i>
                          <!-- if not root folder, only display basename of directories -->
                          <span v-if="directoryBreadcrumbs.length === 0">
                            <u>{{ tFile.fileName }}</u>
                          </span>
                          <span v-else>
                            <u>{{ tFile.fileName.replace(/^.*[\\\/]/, "") }}</u>
                          </span>
                        </div>
                      </div>
                      <div v-else-if="tFile.type === 'back'">
                        <span
                          v-for="(breadcrumb, i) in folderBreadcrumbs"
                          :key="i"
                        >
                          <span
                            v-if="breadcrumb === folderBreadcrumbs.at(-1)"
                            class="btn p-0 cursor"
                            style="cursor: default"
                          >
                            {{ breadcrumb }}
                          </span>
                          <template v-else>
                            <button
                              class="btn btn-hover-bg-light p-0 m-0 text-primary"
                              @click="directoryUp(i)"
                            >
                              {{ breadcrumb }}
                            </button>
                            <span> / </span>
                          </template>
                        </span>
                      </div>
                      <div v-else>
                        <i class="fa-light fa-file pr-3"></i>
                        {{ tFile.fileName }}
                      </div>
                    </div>
                  </div>
                </td>
                <!-- fileSize -->
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      {{ tFile.fileSize }}
                    </div>
                  </div>
                </td>
                <!-- fileType -->
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div v-if="tFile.type === 'directory'">
                        {{ $t("fileExplorer.folder") }}
                      </div>
                      <div v-else>
                        {{ tFile.fileType }}
                      </div>
                    </div>
                  </div>
                </td>
                <!-- lastModified -->
                <td>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      {{ tFile.lastModified }}
                    </div>
                  </div>
                </td>
                <!-- actions -->
                <td>
                  <div class="form-group row float-end">
                    <div
                      v-if="tFile.type !== 'back'"
                      class="col-md-12 col-lg-12 col-xl-12"
                    >
                      <button
                        v-if="
                          tFile.fileType &&
                          ['text', 'json', 'xml'].some(type =>
                            tFile.fileType.includes(type)
                          )
                        "
                        v-b-popover.hover.top="
                          $t('fileExplorer.actions.viewFile')
                        "
                        class="btn btn-icon btn-light btn-sm"
                        @click="viewFile(tFile.filePath)"
                      >
                        <i class="fa-light fa-eye"></i>
                      </button>
                      <button
                        v-if="tFile.type !== 'directory'"
                        v-b-popover.hover.top="
                          $t('fileExplorer.actions.moveFile', {
                            file: tFile.fileName
                          })
                        "
                        class="btn btn-icon btn-light btn-sm ml-1"
                        @click="moveFile(tFile.fileName, tFile.type)"
                      >
                        <i class="fa-light fa-arrow-turn-down-right"></i>
                      </button>
                      <button
                        v-if="tFile.type !== 'directory'"
                        v-b-popover.hover.top="
                          $t('fileExplorer.actions.renameFile', {
                            file: tFile.fileName
                          })
                        "
                        class="btn btn-icon btn-light btn-sm ml-1"
                        @click="rename(tFile.filePath, tFile.fileName)"
                      >
                        <i class="fal fa-pen" />
                      </button>
                      <button
                        v-b-popover.hover.top="
                          $t('fileExplorer.actions.downloadFile', {
                            file: tFile.fileName
                          })
                        "
                        class="btn btn-icon btn-light btn-sm ml-1"
                        @click="downloadFile(tFile.filePath, tFile.fileType)"
                      >
                        <i class="fa-light fa-download"></i>
                      </button>
                      <button
                        v-b-popover.hover.top="
                          $t('fileExplorer.actions.deleteFile', {
                            file: tFile.fileName
                          })
                        "
                        class="btn btn-icon btn-light btn-sm ml-1"
                        @click="deleteFile(tFile.filePath)"
                      >
                        <i class="fal fa-trash" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </div>

      <!-- view file info modal -->
      <b-modal
        ref="fileInfo"
        hide-footer
        size="xl"
        :title="$t('fileExplorer.fileContent')"
      >
        <pre><code ref="codeBlock" class="text-dark" :class="`language-${language}`">{{ highlightedCode }}</code></pre>
        <div
          v-b-popover.hover.top="$t('general.copyToClipboard')"
          class="input-group-prepend cursor-pointer float-right"
          @click="copyToClipboard(file)"
        >
          <span class="input-group-text">
            <i class="fas fa-copy" />
          </span>
        </div>
      </b-modal>
      <!-- move file modal -->
      <b-modal
        ref="moveFileModal"
        centered
        hide-footer
        :title="$t('fileExplorer.moveTo')"
      >
        <MoveFileHelper
          :folders="folders"
          :file="selectFile"
          :file-type="selectFileType"
          @close="closeMoveFileModal"
          @reload="
            loadFiles(
              $store.getters.currentDirectory === 'files'
                ? ''
                : $store.getters.currentDirectory
            )
          "
        ></MoveFileHelper>
      </b-modal>
    </div>
  </div>
</template>

<script>
import FileExplorer from "@/components/FileExplorer/fileExplorer";
import TableWrapper from "@/components/Tools/TableWrapper.vue";
import { mapGetters } from "vuex";
import {
  ADD_FOLDER,
  CHANGE_DIRECTORY,
  LOAD_FILES,
  REMOVE_BREADCRUMB,
  RESET_BREADCRUMBS,
  SET_FILES,
  TOGGLE_ISBUSY
} from "@/core/services/store/fileExplorer.module";
import UploadDropzone from "@/components/FileExplorer/FileUploadDropzone.vue";
import MoveFileHelper from "@/components/FileExplorer/MoveFileHelper.vue";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { highlight, languages } from "prismjs";
import Header from "@/components/Tools/Header/Header.vue";

export default {
  name: "projectFileExplorer",
  components: { Header, UploadDropzone, TableWrapper, MoveFileHelper },
  data() {
    return {
      headerItems: [],
      file: undefined,
      allowedLines: 50,
      directoryPath: [],
      selected: [],
      selectAll: [],
      selectFile: "",
      selectFileType: "",
      language: "plaintext",
      highlightedCode: ""
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"]),
    folderBreadcrumbs() {
      return this.directoryBreadcrumbs.map(b => b.split("/").at(-1));
    },
    files: {
      get() {
        return this.$store.getters.files;
      },
      set(value) {
        return this.$store.dispatch(SET_FILES, value);
      }
    },
    isBusy: {
      get() {
        return this.$store.getters.isBusy;
      },
      set() {
        return this.$store.dispatch(TOGGLE_ISBUSY);
      }
    },
    meta() {
      return this.$store.getters.meta;
    },
    folders() {
      return this.$store.getters.folders;
    },
    directoryBreadcrumbs() {
      return this.$store.getters.directoryBreadcrumbs;
    }
  },
  watch: {
    selectAll(state) {
      if (state.length) {
        Object.keys(this.files).forEach((key, index) => {
          if (this.directoryBreadcrumbs.length > 1 && index === 0) {
            return;
          }
          let entryIndex = parseInt(key);
          this.selected.push(entryIndex);
        });
      } else {
        this.selected = [];
      }
    },
    selected(selected) {
      selected = selected.map(str => parseInt(str));
      let tBody = document.getElementsByTagName("tbody")[0];
      let tableRows = tBody.getElementsByTagName("tr");

      tableRows.forEach((tableRow, index) => {
        if (
          tableRow.getElementsByTagName("td")[0].children[0].children[0]
            .children.length !== 0
        ) {
          if (selected.includes(index)) {
            tableRow.classList.add("bg-light");
          } else {
            tableRow.classList.remove("bg-light");
          }
        }
      });

      this.setHeaderItems();
    }
  },
  mounted() {
    this.resetBreadcrumbs();
    this.loadFiles(
      this.directoryBreadcrumbs ? this.directoryBreadcrumbs.at(-1) : ""
    );
    this.setHeaderItems();
  },
  methods: {
    setHeaderItems() {
      this.headerItems = [];

      if (this.selected.length) {
        this.headerItems.push({
          title:
            this.selected.length + " " + this.$t("fileExplorer.selectedFiles")
        });
        this.headerItems.push({
          type: "button",
          title:
            this.selected.length === 1
              ? this.$t("fileExplorer.downloadFile")
              : this.$t("fileExplorer.downloadFiles"),
          emit: "bulkDownload"
        });
      }

      this.headerItems.push({
        type: "button",
        title: this.$t("fileExplorer.createFolder"),
        icon: "fa-light fa-folder-plus",
        emit: "createFolder"
      });
    },
    toggleIsBusy() {
      this.$store.dispatch(TOGGLE_ISBUSY);
    },
    resetBreadcrumbs() {
      this.$store.dispatch(RESET_BREADCRUMBS);
    },
    prismEditorHighlighter(code) {
      const language =
        this.language !== "undefined" ? this.language : "plaintext";
      this.highlightedCode = highlight(
        code,
        languages[language] ?? languages.plaintext
      );
    },
    loadFiles(directory = "") {
      const query = this.$route.query;
      const params = { ...query, directory };
      this.$store.dispatch(LOAD_FILES, {
        params: params,
        directory: directory,
        changeDir: this.$store.getters.directoryBreadcrumbs.length < 1
      });
    },
    changeDirectory(directory) {
      this.toggleIsBusy();
      this.$store.dispatch(CHANGE_DIRECTORY, directory);
      this.loadFiles(directory);
      this.selected = [];
      this.toggleIsBusy();
    },
    selectItem(index) {
      if (!this.selected.includes(index)) {
        this.selected.push(index);
      } else {
        let itemIndex = this.selected.indexOf(index);
        this.selected.splice(itemIndex, 1);
      }
    },
    directoryUp(index) {
      this.$store.dispatch(REMOVE_BREADCRUMB, index);
      this.loadFiles(this.$store.getters.directoryBreadcrumbs[index]);
    },
    truncatedContent(content) {
      const linesArray = content.split("\n");
      if (linesArray.length <= this.allowedLines) {
        return content;
      } else {
        return linesArray.slice(0, this.allowedLines).join("\n");
      }
    },
    viewFile(filePath) {
      this.toggleIsBusy();
      FileExplorer.getFileContent({ filePath: filePath, download: false })
        .then(response => {
          this.file = this.truncatedContent(response.content);
          this.language = filePath.split(".").pop();
          this.prismEditorHighlighter(this.file);
          this.toggleIsBusy();
          this.$refs["fileInfo"].show();
        })
        .catch(error => {
          this.$error(error);
          this.toggleIsBusy();
        });
    },
    copyToClipboard(variable) {
      copyToClipboard(variable);
      this.$toast.fire({
        title: this.$t("general.copied"),
        icon: "success",
        timeOut: 1000,
        extendedTimeOut: 3000
      });
    },
    downloadFile(filePath, fileType) {
      this.toggleIsBusy();
      let fileOrFolder = !fileType ? "directory" : "file";
      let mimeType = fileType ?? "application/zip";

      FileExplorer.downloadFile({
        filePath: filePath,
        fileType: fileOrFolder
      })
        .then(response => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([response], { type: mimeType })
          );
          link.download = filePath.split("|").reverse()[0];
          document.body.appendChild(link);
          link.click();

          URL.revokeObjectURL(link.href);

          this.toggleIsBusy();
        })
        .catch(error => {
          this.toggleIsBusy();
          this.$error(error);
        });
    },
    bulkDownload() {
      this.toggleIsBusy();
      let filePaths = [];

      if (this.selected.length === 1) {
        return this.downloadFile(this.files[this.selected[0]].filePath);
      }

      this.selected.forEach(select => {
        filePaths.push(this.files[select].filePath);
      });
      FileExplorer.bulkDownload({ filePaths: filePaths })
        .then(response => {
          this.file = response;
          const blob = new Blob([this.file], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filePaths.join("_") + ".zip";
          link.click();
          this.toggleIsBusy();
        })
        .catch(error => {
          this.$error(error);
          this.toggleIsBusy();
        });
    },
    deleteFile(file) {
      this.$swal
        .fire({
          icon: "error",
          title: this.$t("fileExplorer.actions.deleteFile", { file: file }),
          showConfirmButton: true,
          confirmButtonText: this.$t("general.confirm"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          cancelButtonText: this.$t("general.cancel"),
          reverseButtons: true
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.toggleIsBusy();
          FileExplorer.delete(file)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("fileExplorer.deleteSuccess", {
                  file: file
                })
              });
              let lastBreadcrumb =
                this.directoryBreadcrumbs.at(-1) === "files"
                  ? ""
                  : this.directoryBreadcrumbs.at(-1);
              this.loadFiles(lastBreadcrumb);
              this.toggleIsBusy();
            })
            .catch(error => {
              this.$error(error);
              this.toggleIsBusy();
            });
        });
    },
    moveFile(fileName, fileType) {
      this.selectFile = fileName;
      this.selectFileType = fileType;
      this.$refs["moveFileModal"].show();
    },
    closeMoveFileModal() {
      this.$refs["moveFileModal"].hide();
    },
    rename(file, fileName) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("fileExplorer.rename"),
          input: "text",
          inputValue: fileName,
          showConfirmButton: true,
          confirmButtonText: this.$t("general.confirm"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          cancelButtonText: this.$t("general.cancel"),
          reverseButtons: true
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.toggleIsBusy();
          let filePath = file ?? fileName; //file or folder
          let newFileName = result.value;
          FileExplorer.updateName(filePath, newFileName)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("fileExplorer.renameSuccess", {
                  file: result.value
                })
              });
              let lastBreadcrumb =
                this.directoryBreadcrumbs.at(-1) === "files"
                  ? ""
                  : this.directoryBreadcrumbs.at(-1);
              this.loadFiles(lastBreadcrumb);
              this.toggleIsBusy();
            })
            .catch(error => {
              this.$error(error);
              this.toggleIsBusy();
            });
        });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick().then(() => {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    createFolder() {
      this.$swal
        .fire({
          icon: "info",
          title: this.$t("fileExplorer.folderName"),
          input: "text",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.confirm"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          cancelButtonText: this.$t("general.cancel"),
          reverseButtons: true
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.toggleIsBusy();
          let folderName = result.value;
          let folderPath = this.$store.getters.currentDirectory;

          FileExplorer.createFolder(folderPath, folderName)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("fileExplorer.createFolderSuccess", {
                  file: result.value
                })
              });
              this.$store.dispatch(ADD_FOLDER, folderPath + "/" + folderName);
              let lastBreadcrumb =
                this.directoryBreadcrumbs.at(-1) === "files"
                  ? ""
                  : this.directoryBreadcrumbs.at(-1);
              this.loadFiles(lastBreadcrumb);
              this.toggleIsBusy();
            })
            .catch(error => {
              this.$error(error);
              this.toggleIsBusy();
            });
        });
    }
  }
};
</script>
