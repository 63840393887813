<template>
  <v-card class="mx-auto text-subtitle-1" style="box-shadow: none">
    <v-text-field
      v-model="search"
      :label="$t('fileExplorer.searchForFolder')"
      clearable
      dense
      clear-icon="fa-regular fa-circle-xmark"
    ></v-text-field>
    <v-card-text class="py-2" style="height: 20rem; overflow-y: scroll">
      <v-treeview
        activatable
        selection-type="independent"
        dense
        item-disabled="isDisabled"
        :items="items"
        :search="search"
        :open.sync="open"
        @update:active="emitFolderChange"
      >
        <template #prepend="{ item }">
          <i
            v-if="item.children"
            class="pr-3 fa-light"
            :class="open.includes(item.id) ? 'fa-folder-open' : 'fa-folder'"
          ></i>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "FolderTree",
  props: {
    items: {
      type: Array,
      required: true
    },
    child: {
      type: Boolean,
      required: false
    },
    fileToMove: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: null,
      selectedFolder: null,
      open: [0]
    };
  },
  computed: {
    filter() {
      return undefined;
    }
  },
  methods: {
    emitFolderChange(index) {
      this.selectedFolder = +index[0];
      bus.$emit("selectFolder", this.selectedFolder);
    }
  }
};
</script>

<style>
.v-treeview-node__root > button:not(.v-treeview-node__toggle--open) {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}
</style>
