<template>
  <div class="file-upload">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>{{ $t("users.dropFilesToUpload") }}</h3>
    </div>
    <div v-show="!edit" class="file-upload">
      <div class="text-center p-2">
        <file-upload
          v-show="!files.length"
          ref="upload"
          v-model="files"
          :accept="acceptedFileTypes"
          :drop="!edit"
          @input-file="inputFile"
        >
        </file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "vue-upload-component";
import { bus } from "@/main";
import { UPLOAD_FILE } from "@/core/services/store/fileExplorer.module";

export default {
  name: "FileExplorerFileUploadDropzone",
  components: {
    FileUpload
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: () =>
        "text/csv, application/xml, text/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json, image/png, image/jpeg, image/gif, image/tiff, text/html, application/zip",
      required: false
    }
  },
  data() {
    return {
      files: [],
      edit: false,
      maxFileSize: parseInt(process.env.VUE_APP_MAX_FILE_UPLOAD_SIZE_MB) || 5
    };
  },
  computed: {
    fileTypeRegex() {
      return this.acceptedFileTypes.join("|").replace(/\./g, "");
    }
  },
  mounted() {
    bus.$on("fileUploadReset", this.onFileUploadReset);
  },
  destroyed() {
    bus.$off("fileUploadReset", this.onFileUploadReset);
  },
  methods: {
    onFileUploadReset() {
      this.$refs.upload?.clear();
      this.files = [];
    },
    uploadFile(file) {
      this.$store.dispatch(UPLOAD_FILE, {
        data: file,
        directory: this.$store.getters.directoryBreadcrumbs
      });
      this.onFileUploadReset();
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile && this.inputFilter(newFile, oldFile)) {
        this.$nextTick().then(() => {
          this.uploadFile(newFile);
        });
      }
    },
    inputFilter(newFile, oldFile) {
      if (newFile && !oldFile) {
        // TODO, check file extensions from prop "acceptedFileTypes"
        if (
          !/\.(csv|xml|xlsx|txt|json|png|jpeg|jpg|gif|tif|html|zip)$/i.test(
            newFile.name
          )
        ) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("fileExplorer.fileNotAllowed")
          });
          return false;
        } else if (newFile.size / (1024 * 1000) > this.maxFileSize) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("media.maxFileSizeReached", {
              limit: this.maxFileSize
            })
          });
          return false;
        }
        return true;
      }
    }
  }
};
</script>

<style lang="scss">
.file-upload .file-upload .rounded-circle {
  width: 200px;
  height: 200px;
}

.file-upload .text-center .btn {
  margin: 0 0.5rem;
}

.file-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.file-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
