<template>
  <div>
    <table class="table table-head-custom" style="min-width: 100%">
      <tbody>
        <FolderTree
          :items="nestedPaths"
          :file-to-move="file"
          @select-folder="selectFolder"
        />
      </tbody>
    </table>
    <div class="d-flex justify-content-center flex-column">
      <div class="col">
        <input
          type="text"
          class="form-control mt-2"
          :value="currentFilePath"
          disabled
        />
      </div>
      <div class="text-center">
        <button class="align-items-center mt-2">
          <i class="fa-light fa-arrow-down fa-2x"></i>
        </button>
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control mt-2"
          :value="selectedFolder ? selectedFolder + '/' + fileName : ''"
          disabled
        />
      </div>
      <div class="col">
        <b-button
          block
          variant="primary"
          class="mt-2"
          @click="move(currentFilePath, selectedFolder, fileName)"
        >
          {{ $t("fileExplorer.actions.moveFile", { file: file }) }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import FileExplorer from "@/components/FileExplorer/fileExplorer";
import FolderTree from "@/components/FileExplorer/FolderTree.vue";
import { bus } from "@/main";

export default {
  name: "FileStorageHelper",
  components: { FolderTree },
  props: {
    file: {
      type: String,
      default: () => "",
      required: true
    },
    fileType: {
      type: String,
      default: () => "",
      required: true
    },
    folders: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      selectedFolder: ""
    };
  },
  computed: {
    fileName() {
      return this.file.split("/").pop();
    },
    currentFilePath() {
      if (this.fileType) {
        return "files/" + this.file;
      }
      let currentDirectory = this.$store.getters.currentDirectory;
      return currentDirectory !== "files"
        ? "files/" + currentDirectory + "/" + this.file
        : currentDirectory + "/" + this.file;
    },
    currentFolderPath() {
      return (
        "files|" + this.$store.getters.currentDirectory.replaceAll("/", "|")
      );
    },
    nestedPaths() {
      const nestedPaths = [];
      let index = 0;
      const disabledCheck = path => {
        let subFolder = false;
        if (path.length > this.currentFolderPath.length) {
          subFolder = path.startsWith(this.currentFolderPath);
        }
        let mainFolder = path === this.currentFolderPath;

        return subFolder && mainFolder;
      };

      const addFolder = (pathParts, parent, path, isDisabledParent) => {
        const name = pathParts.shift();
        let folder = parent.find(f => f.name === name);

        if (!folder) {
          folder = {
            name,
            id: index++,
            children: [],
            fullPath: path,
            isDisabled:
              disabledCheck(path) ||
              isDisabledParent ||
              (index === 1 &&
                this.$store.getters.directoryBreadcrumbs.length === 1)
          };
          parent.push(folder);
        } else if (!folder.index) {
          folder.index = index++;
        }
        if (pathParts.length > 0) {
          const isDisabledChild =
            isDisabledParent || path === this.currentFolderPath;
          addFolder(pathParts, folder.children, path, isDisabledChild);
        }
      };

      this.folders.forEach(path => {
        addFolder(path.split("|"), nestedPaths, path);
      });
      return nestedPaths;
    }
  },
  mounted() {
    bus.$on("selectFolder", this.selectFolder);
  },
  destroyed() {
    bus.$off("selectFolder", this.selectFolder);
  },
  methods: {
    selectFolder(index) {
      this.selectedFolder = this.reverseNestedPaths(index);
    },
    reverseNestedPaths(index) {
      const findFolder = (folder, index) => {
        if (folder.id === index) {
          return [folder.name];
        } else {
          for (let child of folder.children) {
            const path = findFolder(child, index);
            if (path) {
              return [folder.name, ...path];
            }
          }
          return null;
        }
      };
      for (let folder of this.nestedPaths) {
        const path = findFolder(folder, index);
        if (path) {
          return path.join("/");
        }
      }
      return null;
    },
    move(file, folder, fileName) {
      folder = folder + "/" + fileName;
      let data = {
        from: file.replace(/files\//g, ""),
        to: folder.replace(/files\//g, "")
      };
      FileExplorer.move(data)
        .then(() => {
          this.$emit("reload");
          this.$emit("close");
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
