<template>
  <div id="table-wrapper" :style="cssVars">
    <div class="row align-items-center mb-3">
      <!--begin::Pagination-->
      <div class="col-auto d-flex align-items-center">
        <b-form-select
          v-model="perPageActive"
          :options="pageSizes"
          class="mr-3 shadow-none"
          value-field="item"
          text-field="name"
          size="sm"
          disabled-field="notEnabled"
          @change="handlePageSizeChange"
        ></b-form-select>
        <span class="text-muted text-no-wrap">
          {{ $t("general.recordsPerPage") }}
        </span>
      </div>
      <div style="flex-grow: 1"></div>
      <div class="col-auto d-flex align-items-center justify-content-end">
        <span
          v-b-popover.hover.top="$t('general.reload')"
          class="btn btn-icon btn-sm btn-hover-light-primary"
          @click="reloadData"
        >
          <i class="fal fa-sync-alt"></i>
        </span>
      </div>
      <div class="col-auto d-flex align-items-center justify-content-end">
        <slot name="filter"></slot>
        <div class="input-icon input-icon-right">
          <b-input
            v-if="!disableSearch"
            v-model="searchTerm"
            debounce="700"
            class="ml-1"
            :placeholder="$t('search.searchTerm')"
          />
          <span
            v-if="searchTerm"
            class="cursor-pointer"
            @click="searchTerm = ''"
          >
            <i class="fal fa-xmark-circle" />
          </span>
        </div>
      </div>
      <!--end:: Pagination-->
    </div>
    <div class="dataTables_wrapper overflow-y-auto">
      <slot></slot>
    </div>
    <div class="row justify-content-between align-items-center mt-3">
      <div class="col-auto">
        <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
          {{
            $t("general.records", {
              from: meta.from,
              to: meta.to,
              total: meta.total
            })
          }}
        </span>
      </div>
      <div class="col-auto d-flex flex-wrap">
        <b-pagination
          :key="pKey"
          v-model="page"
          :total-rows="totalRecords"
          :per-page="perPageActive"
          size="md"
          class="pl-0 mb-0"
          :class="{ 'is-page-edit': isPageEdit }"
          first-number
          last-number
          @change="handlePageChange"
        >
          <template #page="data">
            <div v-if="data.active">
              <div
                v-if="isPageEdit"
                class="d-flex align-items-center justify-content-center"
              >
                <input
                  ref="inputPage"
                  v-model="inputPage"
                  type="number"
                  :min="1"
                  :max="totalPages"
                  class="form-control border-white"
                  :class="[isPageEdit ? '' : 'bg-primary text-white']"
                  @keyup.enter="applyPageEdit"
                  @keyup.esc="cancelPageEdit"
                />
                <i
                  class="fal fa-check font-weight-bold text-success ml-2 cursor-pointer"
                  @click="applyPageEdit"
                />
                <i
                  class="fal fa-xmark font-weight-bold text-danger ml-2 cursor-pointer"
                  @click="cancelPageEdit"
                />
              </div>
              <div v-else>
                <span>{{ inputPage }}</span>
                <i
                  class="fal fa-pen text-primary pl-1 cursor-pointer"
                  @click="pageEdit(true)"
                />
              </div>
            </div>
            <div v-else>{{ data.content }}</div>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_ROUTE_PARAMS } from "@/core/services/store/route.module";

const _ = require("lodash");

export default {
  components: {},
  props: {
    meta: {
      type: Object,
      default: () => {}
    },
    perPage: {
      type: Number,
      default: 15
    },
    disableSearch: {
      type: Boolean,
      default: false
    },
    paramPrefix: {
      type: [String, Number],
      default: ""
    },
    noRouteParams: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Meta
      searchTerm: "",
      page: 1,
      totalPages: 1,
      pageSizes: [15, 25, 50, 100],
      totalRecords: 0,
      perPageActive: this.perPage,
      pKey: 1,
      inputPage: 1,
      isPageEdit: false
    };
  },
  created() {
    this.$root.$refs.TableWrapper = this;
  },
  computed: {
    ...mapGetters("route", ["requestParams"]),
    paramNames: function () {
      return ["page", "perPage", "searchTerm"];
    },
    paramNamesPrefixed: function () {
      if (!this.paramPrefix) {
        return this.paramNames;
      }
      let names = [...this.paramNames];
      names.forEach((name, index) => {
        names[index] = this.paramPrefix + "_" + name;
      });
      return names;
    },
    routeParams: function () {
      let params = JSON.parse(JSON.stringify(this.$route.query));
      let requestParams = this.requestParams(this.paramPrefix);
      this.paramNames.forEach((key, index) => {
        requestParams[key]
          ? (params[this.paramNamesPrefixed[index]] = requestParams[key])
          : params[this.paramNamesPrefixed[index]] !== undefined
          ? delete params[this.paramNamesPrefixed[index]]
          : "";
      });
      return params;
    },
    cssVars: function () {
      return {
        "--input-width": `calc(${String(this.inputPage).length}ch + 16px)`
      };
    }
  },
  watch: {
    meta: {
      deep: true,
      handler: function (newMeta, oldMeta) {
        if (_.isEqual(newMeta, oldMeta)) {
          return;
        }
        this.applyMeta();
      }
    },
    searchTerm: function () {
      this.handleSearchTermChange();
    },
    page: function () {
      this.inputPage = this.page;
    }
  },
  mounted() {
    this.applyMeta();
    if (!this.noRouteParams) {
      this.applyRouteParams();
    }
  },
  methods: {
    applyMeta() {
      if (!Object.keys(this.meta).length) {
        return;
      }
      this.page = this.meta.current_page;
      this.totalPages = this.meta.last_page;
      this.totalRecords = this.meta.total;
      this.pKey++;
    },
    applyRouteParams() {
      this.updateStore();
      if (this.noRouteParams) {
        return;
      }
      let query = this.$route.query;
      if (
        !Object.keys(query).some(
          key => this.paramNamesPrefixed.indexOf(key) >= 0
        )
      ) {
        this.setRouteParams();
        return;
      }
      if (query[this.paramNamesPrefixed[0]]) {
        this.page = parseInt(query[this.paramNamesPrefixed[0]]);
      }
      if (query[this.paramNamesPrefixed[1]]) {
        this.perPageActive = parseInt(query[this.paramNamesPrefixed[1]]);
      }
      if (query[this.paramNamesPrefixed[2]]) {
        this.searchTerm = query[this.paramNamesPrefixed[2]];
      }
    },
    setRouteParams() {
      this.updateStore();
      if (!this.noRouteParams) {
        this.$router.replace({ query: this.routeParams }).catch(error => error);
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.reloadData();
    },
    handlePageSizeChange() {
      this.page = 1;
      this.reloadData();
    },
    handleSearchTermChange() {
      if (this.disableSearch) {
        return;
      }
      this.reloadData();
    },
    reloadData() {
      this.setRouteParams();
      setTimeout(() => {
        this.$emit("reload-data", this.routeParams);
      }, 20);
    },
    updateStore() {
      let params = _.zipObject(this.paramNames, [
        this.page,
        this.perPageActive,
        this.searchTerm
      ]);
      this.$store.dispatch("route/" + SET_ROUTE_PARAMS, {
        prefix: this.paramPrefix,
        params: params
      });
    },
    pageEdit(state) {
      this.isPageEdit = state;
      if (state === true) {
        this.$nextTick().then(() => {
          this.$refs.inputPage.focus();
        });
      }
    },
    applyPageEdit() {
      if (isNaN(this.inputPage)) {
        return this.cancelPageEdit();
      } else if (this.inputPage < 1) {
        this.inputPage = 1;
      } else if (this.inputPage > this.totalPages) {
        this.inputPage = this.totalPages;
      }
      this.handlePageChange(this.inputPage);
      this.pageEdit(false);
    },
    cancelPageEdit() {
      this.inputPage = this.page;
      this.pageEdit(false);
    }
  }
};
</script>

<style lang="scss">
#table-wrapper {
  .b-pagination {
    .page-item {
      &.active {
        .page-link {
          cursor: default;
        }
        i {
          font-size: 1rem;
        }
        input {
          &[type="number"] {
            -moz-appearance: textfield;
            width: var(--input-width);
            min-width: 10px;
            outline: none;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              display: none;
            }
            padding: 0 4px;
            margin: -4px 0;
            text-align: center;
            height: 1.75rem;
          }
        }
      }
    }
    &.is-page-edit {
      .page-item {
        &.active {
          .page-link {
            background-color: #fff;
          }
        }
      }
    }
  }
}
.dataTables_wrapper tbody tr:hover {
  background-color: #fbfbfb;
}
.dataTables_wrapper tbody tr:hover td {
  background-color: #fbfbfb;
}
</style>
